import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { getDatabase, ref, set } from 'firebase/database';
import { app } from '../FireBaseConfig'; // Adjust the import path as needed

// Define the styled components
const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
`;

const ItemText = styled.div`
  flex-grow: 1;
  font-size: 16px;
  text-align: left;
`;

const EditInput = styled.input`
  flex-grow: 1;
  font-size: 16px;
  text-align: left;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  line-height: inherit;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #e74c3c;
`;

interface ItemBarProps {
  text: string;
  onRemove: () => void;
  listName: string; // Pass the list name for Firebase reference
  itemId: string; // Pass the item ID for Firebase reference
}

const ItemBar: React.FC<ItemBarProps> = ({ text, onRemove, listName, itemId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(text);
  const inputRef = useRef<HTMLInputElement>(null);

  // Focus the input when entering edit mode
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleToggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    if (editText.trim() === '') {
      // If the input is empty, revert to the original text
      setEditText(text);
      setIsEditing(false);
      return;
    }

    // Update Firebase
    const db = getDatabase(app);
    const itemRef = ref(db, `${listName}/${itemId}`);
    try {
      await set(itemRef, { name: editText });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating item in Firebase:', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditText(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <ItemContainer onClick={handleToggleEdit}>
      {isEditing ? (
        <EditInput
          ref={inputRef}
          value={editText}
          onChange={handleInputChange}
          onBlur={handleSave}
          onKeyPress={handleKeyPress}
        />
      ) : (
        <ItemText>{text}</ItemText>
      )}
      <RemoveButton onClick={onRemove}>X</RemoveButton>
    </ItemContainer>
  );
};

export default ItemBar;