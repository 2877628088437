// ListManager.tsx
import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue, push } from "firebase/database";
import { app } from "./FireBaseConfig";
import List from "./List";
import styles from "./ListManager.module.css";
import styled from "styled-components";

type ListData = {
  id: string;
  name: string;
};

interface ListManagerProps {
  listType?: string; // Optional prop to differentiate between original and /fryser lists
}

// Styled components for the awesome add button
const AddButton = styled.button`
  position: fixed;
  top: 10px;
  left: 20px;
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #0056b3;
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.9);
  }
`;

const PopupContainer = styled.div`
  position: fixed;
  top: 70px;
  left: 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
`;

const PopupInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
`;

const PopupButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const ListManager: React.FC<ListManagerProps> = ({ listType = "lists" }) => {
  const [newListName, setNewListName] = useState("");
  const [lists, setLists] = useState<ListData[]>([]);
  const [isAddingList, setIsAddingList] = useState(false);

  const handleAddList = async (name: string) => {
    if (name.trim() === "") return;

    try {
      const db = getDatabase(app);
      const newListRef = ref(db, listType); // Use listType to determine the Firebase path

      // Check if a list with the same name already exists
      const isNameTaken = lists.some((list) => list.name === name);
      if (isNameTaken) {
        alert("A list with the same name already exists.");
        return;
      }

      const newListData = {
        name: name,
      };

      // Push the new list to the database
      await push(newListRef, newListData);

      // Clear the input field and close the pop-up
      setNewListName("");
      setIsAddingList(false);
    } catch (error) {
      console.error("Error adding list:", error);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddList(newListName);
    }
  };

  useEffect(() => {
    const db = getDatabase(app);
    const listsRef = ref(db, listType); // Use listType to determine the Firebase path

    onValue(listsRef, (snapshot) => {
      const data = snapshot.val() || {};
      const listsData = Object.keys(data).map((key) => ({
        id: key,
        name: data[key].name,
      }));
      setLists(listsData);
    });
  }, [listType]);

  return (
    <div className={styles["list-manager"]}>
      {/* Awesome Add Button */}
      <AddButton onClick={() => setIsAddingList(true)}>+</AddButton>

      {/* Popup for adding a new list */}
      {isAddingList && (
        <PopupContainer>
          <PopupInput
            type="text"
            placeholder="Navn på liste"
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
            onKeyPress={handleKeyPress}
            autoFocus={true}
          />
          <PopupButton onClick={() => handleAddList(newListName)}>
            Tilføj Liste
          </PopupButton>
          <PopupButton onClick={() => setIsAddingList(false)}>
            Annuller
          </PopupButton>
        </PopupContainer>
      )}

      {/* Render the lists */}
      {lists.map((list) => (
        <div key={list.id} className={styles["inner-list"]}>
            <List name={list.name} listType={listType} /> {/* Pass listType prop */}
        </div>
        ))}
    </div>
  );
};

export default ListManager;