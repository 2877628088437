import React, { useState, useEffect, useRef } from "react";
import "./List.css";
import {
    getDatabase,
    ref,
    push,
    onValue,
    DataSnapshot,
    remove,
    set,
} from "firebase/database";
import { app } from "./FireBaseConfig";
import ItemBar from "./utils/ItemBar";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
var md5 = require("md5");

interface ListProps {
    name: string;
    listType: string; // Add listType prop
}

function List({ name, listType }: ListProps) {
    console.log("List component rendering"); // Log when the component renders

    const [product, setProduct] = useState("");
    const [productsList, setProductsList] = useState<{ id: string; name: string }[]>([]); // Use an array of objects containing id and name
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [draggedItemId, setDraggedItemId] = useState<string | null>(null);

    const db = getDatabase(app);
    const productsRef = ref(db, name); // Lists are stored directly in the parent folder

    useEffect(() => {
        onValue(productsRef, (snapshot: DataSnapshot) => {
            const productsData: any = snapshot.val();
            if (productsData) {
                const productList = Object.entries(productsData)
                    .map(([id, productData]: [string, any]) => ({
                        id,
                        name: productData.name,
                        order: productData.order, // Include the order property
                    }))
                    .sort((a, b) => a.order - b.order); // Sort by order

                setProductsList(productList);
            } else {
                setProductsList([]);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddProduct = () => {
        console.log("Adding a product:", product); // Log when adding a product
        if (product.trim() !== "") {
            const newProduct = {
                name: product,
                id: md5(Date.now()).toString(),
            };

            push(productsRef, newProduct);

            setProduct("");
            inputRef.current?.focus();
        }
    };

    const handleClearList = () => {
        console.log("Clearing the list"); // Log when clearing the list
        setProductsList([]);
        remove(productsRef);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleAddProduct();
        }
    };

    const handleDeleteList = () => {
        const confirmDelete = window.confirm("Er du sikker på du vil slette listen?");

        if (!confirmDelete) return;

        const db = getDatabase(app);
        const listRef = ref(db, name); // Lists are stored directly in the parent folder
        const listsRef = ref(db, listType); // Use listType to determine the collection (lists or fryser)
        const productsListRef = ref(db, name); // Lists are stored directly in the parent folder

        remove(listRef)
            .then(() => {
                onValue(productsListRef, (snapshot) => {
                    const data = snapshot.val() || {};
                    Promise.all(Object.keys(data).map(key => remove(ref(db, `${name}/${key}`))))
                        .then(() => {
                            // Now, remove the list from the 'lists' or 'fryser' collection
                            onValue(listsRef, (snapshot) => {
                                const listsData = snapshot.val() || {};
                                for (const key in listsData) {
                                    if (listsData[key].name === name) {
                                        remove(ref(db, `${listType}/${key}`))
                                            .then(() => console.log("List and its entry deleted successfully"))
                                            .catch((error) => console.error("Error deleting list entry:", error));
                                    }
                                }
                            });
                        })
                        .catch((error) => console.error("Error deleting product:", error));
                });
            })
            .catch((error) => console.error("Error deleting list:", error));
    };

    const handleRemoveProduct = (idToRemove: string) => {
        console.log("Removing a product with ID:", idToRemove); // Log when removing a product
        const newProductsList = productsList.filter(
            (productItem) => productItem.id !== idToRemove
        );
        setProductsList(newProductsList);

        remove(ref(db, `${name}/${idToRemove}`)); // Lists are stored directly in the parent folder
    };

    const handleDragEnd = async (result: any) => {
        if (!result.destination) {
            return;
        }

        const reorderedProducts = [...productsList];
        const [movedItem] = reorderedProducts.splice(result.source.index, 1);
        reorderedProducts.splice(result.destination.index, 0, movedItem);

        // Define a type for updatedProducts
        type UpdatedProducts = { [key: string]: { name: string; order: number } };
        const updatedProducts: UpdatedProducts = {};

        reorderedProducts.forEach((product, index) => {
            updatedProducts[product.id] = {
                name: product.name,
                order: index,
            };
        });

        await set(ref(db, name), updatedProducts); // Lists are stored directly in the parent folder

        setProductsList(reorderedProducts);
        console.log("Reordered products:", reorderedProducts); // Log the reordered products

        // Set the currently dragged item's ID
        setDraggedItemId(null);
    };

    return (
        <div className="container">
            <div className="title">
                <h1>{name}</h1>
            </div>
            <div className="clear-list">
                <button className="clear-button" onClick={handleClearList}>
                    Ryd Liste
                </button>
                <button className="clear-button" onClick={handleDeleteList}>
                    Slet List
                </button>
            </div>
            <div className="list">
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="productList">
                        {(provided: any) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="products-list"
                            >
                                {productsList.map((productItem, index) => (
                                    <Draggable
                                        key={productItem.id}
                                        draggableId={productItem.id}
                                        index={index}
                                    >
                                        {(provided: any, snapshot: any) => (
                                            <div
                                                className={`product-card ${snapshot.isDragging ? "dragging" : ""
                                                    } ${draggedItemId === productItem.id ? "highlight" : ""}`} // Apply the 'highlight' class if it's the currently dragged item
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                id={productItem.id}
                                                onMouseEnter={() => setDraggedItemId(productItem.id)} // Set the currently dragged item's ID on mouse enter
                                                onMouseLeave={() => setDraggedItemId(null)} // Reset the currently dragged item's ID on mouse leave
                                            >
                                                <ItemBar
                                                    text={productItem.name}
                                                    onRemove={() => handleRemoveProduct(productItem.id)}
                                                    listName={name} // Pass the list name
                                                    itemId={productItem.id} // Pass the item ID
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div className="add-product">
                    <input
                        ref={inputRef}
                        type="text"
                        autoCapitalize="sentences"
                        placeholder="Tilføj Produkt"
                        // When focusing the input, the placeholder disappears
                        onFocus={(event) => {
                            event.target.setAttribute("placeholder", "");
                            event.target.setAttribute("autoCapitalize", "sentences"); // Enable autocapitalization on focus
                        }}
                        // When blurring the input, the placeholder reappears
                        onBlur={(event) => {
                            event.target.setAttribute("placeholder", "Tilføj Produkt");
                            event.target.setAttribute("autoCapitalize", "none"); // Reset autocapitalization on blur
                        }}
                        value={product}
                        onChange={(event) => setProduct(event.target.value)}
                        onKeyPress={handleKeyPress}
                        className="plain-input"
                    />
                </div>
            </div>
        </div>
    );
}

export default List;