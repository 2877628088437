// App.tsx
import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import ListManager from "./components/ListManager";
import Codes from "./components/codes";
import RouteToggle from "./components/RouteToggle"; // Import the new component

const App: React.FC = () => {
  return (
    <>
      <RouteToggle /> {/* Add the toggle component here */}
      <Routes>
        {/* Original lists */}
        <Route
          path="/"
          element={
            <div className="lists">
              <ListManager listType="lists" /> {/* Use listType="lists" for / */}
              <Codes word="hidden"/> {/* Pass listType="lists" */}
              <Codes word="Work"/> {/* Pass listType="lists" */}
              <Codes word="Mikkel"/> {/* Pass listType="lists" */}
            </div>
          }
        />

        {/* Separate lists for /fryser */}
        <Route
          path="/fryser"
          element={
            <div className="lists">
              <ListManager listType="fryser" /> {/* Use listType="fryser" for /fryser */}
              <Codes word="fryser"/> {/* Pass listType="fryser" */}
            </div>
          }
        />
      </Routes>
    </>
  );
};

export default App;