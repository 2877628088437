import React, { useState, useEffect } from 'react';
import List from "./List";

function Codes({ word }: { word: string }) {
    const [showDiv, setShowDiv] = useState(false);
    const [typedWord, setTypedWord] = useState('');

    useEffect(() => {
        const handleKeyPress = (event: any) => {
            const keyPressed = event.key.toLowerCase();
            setTypedWord((prevTypedWord) => prevTypedWord + keyPressed);

            // Check if the typedWord includes the provided word
            if ((typedWord + keyPressed).includes(word.toLowerCase())) {
                myFunction();
                setShowDiv(true);
            }
        };

        const myFunction = () => {
            // Your custom function logic here
            console.log(`User spelled "${word}"`);
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [typedWord, word]);

    return (
        <div>
            {showDiv && (
                <div className="inner-list">
                    <List name={word} listType="lists" /> {/* Pass listType prop */}
                </div>
            )}
        </div>
    );
}

export default Codes;