import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

const ToggleContainer = styled.div`
  position: fixed;
  top: 10px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #007bff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above other elements */

  &:hover {
    background-color: #0056b3;
  }
`;

const ToggleIcon = styled.span`
  font-size: 24px;
  color: white;
`;

const RouteToggle: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isFreezer, setIsFreezer] = useState(location.pathname === "/fryser");

  const handleToggle = () => {
    if (isFreezer) {
      navigate("/");
    } else {
      navigate("/fryser");
    }
    setIsFreezer(!isFreezer);
  };

  useEffect(() => {
    setIsFreezer(location.pathname === "/fryser");
  }, [location.pathname]);

  return (
    <ToggleContainer onClick={handleToggle}>
      <ToggleIcon>{isFreezer ? "❄️" : "📋"}</ToggleIcon>
    </ToggleContainer>
  );
};

export default RouteToggle;